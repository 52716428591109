import { render, staticRenderFns } from "./KnTableGroupActions.vue?vue&type=template&id=27081a83"
import script from "./KnTableGroupActions.js?vue&type=script&lang=js&external"
export * from "./KnTableGroupActions.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports