import { mapState } from 'vuex';

// import { getItem } from '../../../../api/api-methods';
import { postPromoteStudents } from '../../helpers/actionsGroupsOptions';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import { fetchGroups } from '../../helpers/reportCardOptions';

export default {
  name: 'ModalChangeGroup',
  components: {
    KnLocalAlert,
  },
  props: {
    showGroupModal: Boolean,
    currentGroup: Object,
  },
  data() {
    return {
      selectedGroup: null,
      groups: [],
      loading: false,
      loadingAction: false,
      loadingSearch: false,
      // updateTuition: false,
      // selectedTuition: null,
      tuitionOptions: [],

      // Alert component
      showAlert: false,
      alertText: '',
      alertType: '',
      alertColor: null,
      errors: [],
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    sucursalName() {
      return this.currentGroup.sucursal?.nombre_sucursal || 'N/A';
    },
    salonName() {
      return this.currentGroup.salon_clases?.nombre_salon || 'N/A';
    },
    canProceed() {
      return this.currentGroup.ciclo_escolar && this.selectedGroup;
    },
    profesoresCount() {
      return this.currentGroup?.profesores?.length || 0;
    },
    alumnosCount() {
      return this.currentGroup?.alumnos?.length || 0;
    },
  },
  async created() {
    this.loading = true;
    try {
      await this.getGroups();
      // await this.getTuitions();
    } catch (error) {
      console.log('Error al al montar el componente', error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = 'success';
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion
    getSchoolYear(group) {
      if (!group || !group.ciclo_escolar || !group.ciclo_escolar.dato) {
        return 'School year unavailable';
      } else {
        return group.ciclo_escolar.dato;
      }
    },
    async getGroups(search = null) {
      try {
        this.loadingSearch = true;
        const { ok, data } = await fetchGroups({
          institutionId: this.institutionId,
          systemStatus: true,
          groupName: search,
          limit: 100,
        });
        if (ok) {
          this.groups = data.filter(
            (group) => group.id !== this.currentGroup.id
          );
        }
      } catch (error) {
        console.error('No se pudieron cargar los grupo', error);
      } finally {
        this.loadingSearch = false;
      }
    },
    // async getTuitions() {
    //   try {
    //     const allTuitions = await getItem(
    //       `/app-personas/filters/colegiatura?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=100`
    //     );
    //     this.tuitionOptions = allTuitions.results;
    //   } catch (error) {
    //     console.error('No se pudieron cargar los grupo', error);
    //   } finally {
    //     this.loadingSearch = false;
    //   }
    // },

    action1() {
      this.selectedGroup = null;
      // this.updateTuition = false;
      // this.selectedTuition = null;
      this.$emit('actionClose');
    },
    actionAlertBtn1() {
      this.errors = [];
      this.showAlert = false;
    },
    continueAdding() {
      this.errors = [];
      this.showAlert = false;
    },
    async confirmGroupChange() {
      try {
        this.setLoadingState(true, `Actualizando alumnos del grupo`, 'info');
        const { ok, error } = await postPromoteStudents(
          this.currentGroup.id,
          this.selectedGroup
        );

        if (!ok)
          this.setErrorState(
            `Error al actualizar los datos del grupo ${this.currentGroup.nombre_grupo}, Error:${error}. Por favor, inténtelo de nuevo.`
          );
        else {
          this.selectedGroup = null;
          this.currentGroup.alumnos = [];
          this.setSuccessState(
            `Se trasladaron los alumnos del ${this.currentGroup.nombre_grupo} al nuevo grupo correctamente`
          );
        }
      } catch (error) {
        // console.log('Error al intentar transferir alumnos de grupo');
        this.setErrorState(
          `Error al actualizar los datos del grupo ${this.currentGroup.nombre_grupo}. Por favor, inténtelo de nuevo.`
        );
      }
    },
    async handleSearch(item) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      await this.getGroups(item);
    },
  },
};
